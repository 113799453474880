import React from 'react'

import Layout from '../components/layout'
import Location from '../components/location'
import OpeningHours from '../components/opening-hours'
import Directions from '../components/directions'

const IndexPage = ({ pageContext }) => (
    <Layout pageContext={pageContext}>
      <Location name="Waihi" />
      <Directions />
      <OpeningHours />
    </Layout>
)

export default IndexPage
